body {
  position: relative;
  font-family: "PFDinDisplayPro", "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  min-width: $width-min;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  border: 0;
}

* {
  outline-color: $orange;
}

.no-scroll {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

// Стили для библиотеки Slick
.slick-track {
  display: flex;
}

.scroll-hidden {
  overflow: hidden;
}
