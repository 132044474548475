.feedback {
  padding-bottom: 76px;
  background-color: $beige;
  background-image: url("../img/svg/shape_logo.svg");
  background-repeat: no-repeat;
  background-size: 40% auto;
  background-position: top right;

  &__title {
    @include h2Styles();
    color: $brown-light;

    margin-bottom: 61px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: $bp-small) {
      padding-left: 5px;
      padding-right: 5px;
    }

    &::after {
      @include h2Underline();
    }
  }

  &__form,
  &__employees {
    box-sizing: border-box;
    padding-top: 30px;
  }
}

@media screen and (min-width: $bp-small) {
  .feedback {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .feedback {
    padding-top: 210px;

    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &__form {
      flex-basis: 60%;
    }

    &__employees {
      flex-basis: 40%;
    }

    &__list {
      flex-direction: column;
    }
  }
}

@media screen and (min-width: $bp-large) {
  .feedback {
    background-size: 30% auto;

    &__wrapper {
      display: flex;
    }

    &__form {
      flex-basis: 50%;
    }

    &__employees {
      flex-basis: 50%;
      align-self: center;
    }

    &__list {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .feedback {
    padding-top: 74px;
    background-size: 650px auto;

    &__title {
      margin-bottom: 50px;

      &--fdb {
        text-align: left;
        padding-left: 0;
        padding-right: 0;

        &::after {
          left: 0;
          transform: translateX(0);
        }
      }
    }

    &__wrapper {
      justify-content: flex-start;
    }

    &__form {
      flex-basis: unset;
    }

    &__employees {
      flex-basis: unset;
      align-self: unset;
      padding-left: 124px;
      padding-top: 94px;
    }

    &__item {
      &:first-child {
        padding-right: 48px;
      }
    }
  }
}
