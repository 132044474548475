.reviews {
  padding-top: 76px;
  padding-bottom: 67px;

  @media (max-width: $bp-small) {
    padding-top: 130px;
  }
}

.reviews__slider-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: $bp-xlarge) {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.reviews__slider-btn {
  position: relative;
  margin-top: 58px;
  padding: 0;
  width: 19px;
  height: 33px;
  font-size: 0;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

.reviews__slider-icon {
  fill: $green;
  transition: all 0.3s ease-out;

  &:hover {
    fill: $orange;
  }
}

.reviews__slider {
  margin: 0 auto;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}
