.employee {
  margin: 0;

  &__photo {
    display: block;
    width: 150px;
    height: auto;
    margin: 0 auto;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px 0;
  }

  &__name {
    align-self: center;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    color: $grey-dark;
  }

  &__position {
    align-self: center;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 300;
    color: $green-employee;
  }
}


@media screen and (min-width: $bp-large) {
  .employee {
    &__name {
      font-size: 20px;
      line-height: 1.6;
    }

    &__position {
      font-size: 16px;
      line-height: 1.18;
    }
  }
}
