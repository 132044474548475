.order {
  position: relative;
  padding-top: 76px;
  padding-bottom: 30px;
  background-color: #342e22;
  // background-image: url("../img/picture/bg-order.jpg");
  background-size: 100% 100%;

  // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
  //   background-image: url("../img/picture/bg-order@2x.jpg");
  // }

  @media (max-width: $bp-large) {
    background-image: none;
  }
}
