.features {
  position: relative;
  padding-top: 75px;
  padding-bottom: 31px;
  background-color: $beige;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 875px;
    height: 525px;
    background-image: linear-gradient(to bottom right, transparent 0, transparent 50%, #f0e6cd 50%, #f0e6cd 100%);
    z-index: -1;
  }
}
