.materials {
  position: relative;
  padding-top: 76px;
  padding-bottom: 3px;
  background-color: $brown-light;
  z-index: 0;
  overflow: hidden;

  &--load {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -15px;
      width: 101%;
      height: 724px;
      background-image: url("../img/picture/bg-materials-left.png");
      background-repeat: no-repeat;
      background-size: 1484px auto;
      z-index: -1;
  
      // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      //   background-image: url("../img/picture/bg-materials-left@2x.png");
      //   background-size: 1484px auto;
      // }
  
      @media (max-width: $bp-xlarge) {
        left: 0;
      }
    }
  
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: -15px;
      width: 101%;
      height: 532px;
      background-image: url("../img/picture/bg-materials-right.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 888px auto;
      z-index: -1;
  
      // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      //   background-image: url("../img/picture/bg-materials-right@2x.png");
      //   background-size: 888px auto;
      // }
  
      @media (max-width: $bp-xlarge) {
        right: 0;
      }
    }
  }  
}

.materials__subtitle {
  @include h4Styles(49.58px);
  color: $white;
  margin-bottom: 5px;
  text-align: center;
}

.materials__link {
  display: block;
  margin-left: auto;
  margin-bottom: 74px;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 21px;
  width: 140px;
  color: $green;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.17;
  background-color: $white;
  text-decoration: none;
  text-transform: uppercase;
  text-align: right;
  border-radius: 22px 0 0 22px;
  box-sizing: border-box;
  letter-spacing: -0.2px;
  transition: all 0.5s ease-out;

  @media (max-width: $bp-xlarge) {
    margin-right: auto;
    border-radius: 22px;
  }

  &:hover {
    color: #367d38;
    background-color: $beige;
  }
}

.materials__separator {
  margin-bottom: 52px;
  height: 2px;
  background-color: #6e5040;

  @media (max-width: $bp-xlarge) {
    margin-left: 30px;
    margin-right: 30px;
  }
}
