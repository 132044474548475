.user-content {
    font-size: 18px;
    line-height: 1.5em;

    ul, ol {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 40px;
    }
     
    ul {
        list-style-type: disc; 
    }

    ol { 
        list-style-type: decimal; 
     }

    li {
        list-style: inherit;
    }

    // Processwire CKeditor styles
    figure {
        display: table;
        margin: 1em 0;
    }

    figure figcaption {
        display: table-caption;
        caption-side: bottom;
        line-height: 1.3em;
        color: #777; 
        font-size: 13px; 
    }

    img.right,
    img.align_right,
    img.align-right,
    figure.align_right,
    figure.align-right {
        /* RCD */
        float: right;
        margin-left: 15px;
        margin-right: 0;
        margin-top: 0;
    }
    
    img.left,
    img.align_left,
    img.align-left,
    figure.align_left,
    figure.align-left {
        /* RCD */
        float: left;
        margin-top: 0;
        margin-left: 0;
        margin-right: 15px;
    }
    
    img.align_center,
    img.align-center,
    figure.align_center,
    figure.align-center {
        /* RCD */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}