.call-request {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  display: none;
  width: 90%;

  &--open {
    display: block;
  }
}

@media screen  and (min-width: $bp-small) {
  .call-request {
    width: 70%;
  }
}

@media screen  and (min-width: $bp-medium) {
  .call-request {
    width: 352px;
  }
}

@media screen  and (min-width: $bp-large) {
  .call-request {
    top: 72px;
    transform: translate(-50%, 0);
  }
}
