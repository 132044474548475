.header {
  position: fixed;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  min-width: $width-min;
  background-color: $white;

  &__top-content {
    width: 100%;
    text-align: center;
    padding: 0.3em 0.5em 0.4em;
    margin-bottom: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #BF3030;
  }

  &__logo-wrapper-bp-medium {
    display: none;
  }

  &__logo-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 82px;
    padding-top: 6px;
    padding-bottom: 10px;
    padding-left: 27px;
  }

  &__logo {
    width: 218px;
    height: auto;
    margin: auto;
  }

  &__contact {
    box-sizing: border-box;
    flex-basis: 50%;
    height: 44px;
    overflow: hidden;
    margin-top: 0;

    &--open {
      flex-basis: 100%;
      height: 100vh;
      overflow-y: auto;
      margin-top: -82px;
    }
  }

  &__menu {
    box-sizing: border-box;
    flex-basis: 50%;
    height: 44px;
    overflow: hidden;
    margin-top: 0;

    &--open {
      flex-basis: 100%;
      height: 100vh;
      overflow-y: auto;
      margin-top: -126px; // logo height + contact height
    }
  }
}

@media screen and (min-width: $bp-small) {
  .header {
    //outline: 2px solid yellow;
  }
}

@media screen and (min-width: $bp-medium) {
  .header {
    //outline: 2px solid magenta;
    padding-top: 12px;

    &__logo-wrapper-bp-medium {
      display: block;
      width: 180px;
    }

    &__logo-wrapper {
      display: none;
    }


    &__logo-bp-medium {
      width: 180px;
      height: auto;
    }

    &__contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;
      flex-basis: auto;
      width: 100%;
      height: auto;
      overflow-y: auto;
    }

    &__menu {
      flex-basis: 100%;
      height: auto;
      overflow-y: auto;
    }
  }
}

@media screen and (min-width: $bp-large) {
  .header {
    //outline: 2px solid blue;

    &__logo {
      width: 170px;
      height: auto;
    }

    &__contact {
      padding: 0 30px;
      margin: 0 auto;
      max-width: 1110px;
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .header {
    //outline: 2px solid red;
    width: 100%;

    &__logo-wrapper-bp-medium {
      width: 254px;
    }

    &__logo-bp-medium {
      width: 254px;
    }

    &__contact {
      justify-content: unset;
      max-width: 1110px;
      margin: 0 auto;
      padding: 0;
    }
  }
}

@media screen and (min-width: 1920px) {
  .header {
    //outline: 2px solid aqua;
  }
}
