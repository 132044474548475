.checkbox {
    margin-bottom: 10px;
    width: 100%;
}
/* для элемента input c type="checkbox" */
.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    cursor: pointer;

    &--light {
        color: #fff;
        border-color: $orange; 

        & a {
            color: #fff;
        }

        & input {
            border-color: $orange;
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }
    }
    }

    /* для элемента label, связанного с .custom-checkbox */
    .custom-checkbox+label {
    display: inline;
    align-items: center;
    user-select: none;
    }

    .custom-checkbox+label a {
        color: #ff6600;
        text-decoration: underline;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $green;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    transform: translateY(3px);
    }

    .custom-checkbox--light .custom-checkbox+label::before {
        border-color: $orange;
    }

    /* стили при наведении курсора на checkbox */
    .custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: $green;
    }
    
    .custom-checkbox--light .custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: $orange;
    }  

    /* стили для активного чекбокса (при нажатии на него) */
    .custom-checkbox:not(:disabled):active+label::before {
    background-color: $green;
    border-color: $green;
    }
    
    .custom-checkbox--light .custom-checkbox:not(:disabled):active+label::before {
    border-color: $orange;
    background-color: $green;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .custom-checkbox:checked+label::before {
    border-color: $green;
    background-color: $green;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox--light .custom-checkbox:checked+label::before {
    border-color: $orange;
    background-color: $green;
    }