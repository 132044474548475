.contact {
  color: $white;
  background-color: $green;

  &__content {
    padding-left: 28px;
    padding-right: 50px;
    height: 0;
  }

  &__call-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__messenger-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
  }

  &__messenger-icon {
    width: 40px;
    margin-right: 5px;
    padding-bottom: 10px;
  }

  &__tel-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
  }

  &__tel {
    position: relative;
    margin-right: auto;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 6px;
      width: 100%;
      height: 2px;
      transition: all 1s ease-out;
    }

    &:focus::before,
    &:hover::before {
      background-color: $green-tel-hover;
    }
  }

  &__address {
    padding-top: 10px;
    padding-bottom: 14px;
    padding-right: 10px;
    font-size: 17px;
    line-height: 1;
  }

  &__hours {
    flex-basis: 100%;
    padding-bottom: 4px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: 300;
  }

  &__btn {
    transition: all 500ms ease-out;

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen  and (min-width: $bp-medium) {
  .contact {
    color: $grey-light;
    background-color: $white;

    &__content {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 0;
      height: auto;
    }

    &__address-hours-wrapper {
      box-sizing: border-box;
      width: 350px;
      padding-right: 40px;
      text-align: right;
    }

    &__address {
      padding: 0;
      padding-bottom: 8px;
      color: $brown-light;
    }

    &__messenger-icon {
      padding-bottom: 0;
    }

    &__call-wrapper {
      box-sizing: border-box;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 0;
    }

    &__tel {
      padding-bottom: 4px;
      color: $green;

      &::before {
        bottom: 2px;
      }
    }

    &__btn {
      color: $white;
      border-color: $green;
      background-color: $green;

      &:hover {
        color: $white;
        border-color: $green-dark;
        background-color: $green-dark;
      }
    }
  }
}

@media screen and (min-width: $bp-large) {
  .contact {
    display: flex;

    &__content {
      justify-content: flex-start;
      padding-left: 0;
    }

    &__address-hours-wrapper {
      padding-right: 16px;
      line-height: 1;
    }

    &__call-wrapper {
      display: flex;
    }

    &__tel-wrapper {
      font-size: 20px;
    }

    &__btn {
      align-self: start;
      margin-left: 14px;
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .contact {
    &__content {
      // padding-top: 7px;
      margin-left: auto;
    }

    &__address-hours-wrapper {
      padding: 0;
      padding-right: 10px;
      padding-bottom: 10px;
      text-align: right;
    }

    &__address {
      font-size: 17px;
      padding-bottom: 10px;
    }

    &__call-wrapper {
      padding-top: 6px;
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 0;
    }
  }
}
