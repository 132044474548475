.breadcrumbs {
  text-align: center;
  padding-bottom: 10px;
}

.breadcrumbs__item {
  display: inline-block;
  
  &::after {
    content: " / ";
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.breadcrumbs__link {
  font-size: 1.2em;
  color: $orange;

  &:hover {
    color: $orange-dark;
    text-decoration: underline;
  }
}
