.advices {
  padding-top: 75px;
  padding-bottom: 71px;
  position: relative;
  background-color: #39853c;
  z-index: 0;


  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 626px;
    height: 375px;
    background-image: linear-gradient(to top left, transparent 0, transparent 50%, $green 50%, $green 100%);
    z-index: -1;

    @media (max-width: $bp-xlarge) {
      width: 400px;
      height: 250px;
    }

    @media (max-width: $bp-small) {
      width: 300px;
      height: auto;
    }
  }
}

.advices__subtitle {
  @include h4Styles(26.25px);
  color: $white;
  margin-bottom: 66px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  letter-spacing: -0.08px;
}

.advices__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: $bp-xlarge) {
    flex-direction: column;
    align-items: center;
  }
}

.advices__text {
  margin: 0;
  max-width: 868px;
  color: $white;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.13;
  letter-spacing: -0.08px;

  @media (max-width: $bp-xlarge) {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: $bp-small) {
    max-width: 320px;
    box-sizing: border-box;
  }
}
