.footer {
  color: $white;
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    background-color: $brown-light;
  }

  &__logo {
    width: 100px;
    height: auto;
  }

  &__address {
    font-size: 14px;
    line-height: 1.14;
  }

  &__note {
    padding-bottom: 8px;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
  }

  &__hours {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 300;
    text-transform: uppercase;

    &--first {
      padding-top: 8px;
    }

    &--last {
      padding-bottom: 8px;
    }
  }

  &__tel-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
  }

  &__tel {
    margin-right: auto;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    color: $white;
    border-bottom: 1px solid transparent;
    transition: all 500ms ease-out;

    &:focus,
    &:hover {
      border-bottom: 1px solid $white;
    }
  }

  &__email {
    display: block;
    margin-right: auto;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1;
    color: $white;
    border-bottom: 1px solid transparent;
    transition: all 500ms ease-out;

    &:hover {
      border-bottom: 1px solid $white;
    }
  }

  &__colorshkaf {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__colorshkaf-logo {
    max-width: 100px;
  }

  &__colorshkaf-link {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 1;
    color: $white;
    border-bottom: 1px solid transparent;
    transition: all 500ms ease-out;

    &:hover {
      border-bottom: 1px solid $white;
    }
  }

  &__bank {
    font-weight: 300;
    font-size: 10px;
    line-height: 1.2;
  }

  &__map {
    width: 100%;
    background-color: $beige;
  }
}

@media screen and (min-width: $bp-medium) {
  .footer {
    &__wrapper {
      padding: 30px;
      z-index: 1;
      width: 380px;
      min-height: 460px;
    }

    &__logo {
      width: 130px;
      padding-bottom: 10px;
    }

    &__note {
      padding-bottom: 10px;
    }

    &__tel-wrapper {
      padding-bottom: 10px;
    }

    &__email {
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: $bp-large) {
  .footer {
    &__wrapper {
      z-index: 1;
      width: 380px;
      min-height: 460px;
    }

    &__logo {
      width: 255px;
      padding-bottom: 32px;
    }

    &__address {
      font-size: 16px;
      line-height: 1.25;
    }

    &__note {
      padding-bottom: 4px;
      font-size: 14px;
      line-height: 1.42;
    }

    &__hours {
      font-size: 14px;
      line-height: 1.125;

      &--first {
        padding-top: 12px;
      }

      &--last {
        padding-bottom: 12px;
      }
    }

    &__tel-wrapper {
      padding-bottom: 8px;
    }

    &__tel {
      font-size: 20px;
      line-height: 1.1;
    }

    &__colorshkaf {
      margin-top: 15px;
      margin-bottom: 30px;      
    }

    &__bank {
      font-size: 12px;
      line-height: 1.16;
    }
  }
}
