.btn {
  box-sizing: border-box;
  padding-top: 14px;
  padding-bottom: 11px;
  padding-left: 16px;
  padding-right: 22px;
  font-size: 17px;
  line-height: 1;
  text-transform: uppercase;
  color: $white;
  border: 1px solid $white;
  border-radius: 0 22px 22px 0;
  cursor: pointer;
  transition-property: border, background-color;
  transition-duration: 500ms;
  transition-timing-function: ease-out;

  &--banner {
    border-color: $green;
    background-color: $green;

    &:focus,
    &:hover {
      border-color: $green-dark;
      background-color: $green-dark;
    }
  }

  &--call {
    color: $green;
    font-weight: 300;
    border-color: $white;
    background-color: $white;
  }

  &--promo {
    color: $green;
    font-weight: 700;
    border-color: $white;
    background-color: $white;

    &:focus,
    &:hover {
      color: $white;
      border-color: $green;
      background-color: $green;
    }
  }

  &--orange {
    font-weight: 700;
    border-color: $orange;
    background-color: $orange;

    &:focus,
    &:hover {
      border-color: $orange-dark;
      background-color: $orange-dark;
    }
  }

  &--grey {
    border-color: $grey-btn;
    background-color: $grey-btn;

    &:focus,
    &:hover {
      border-color: $grey-btn-hover;
      background-color: $grey-btn-hover;
    }
  }
}
