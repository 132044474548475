.toggle {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-out;


  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &--contact::before {
    background-image: url("../img/svg/phone.svg");
  }

  &--menu::before {
    background-image: url("../img/svg/menu.svg");
  }

  &--block-open {
    width: 44px;
    margin-left: calc(100% - 75px);

    &::before {
      background-image: url("../img/svg/close.svg");
    }
  }

  &:focus,
  &:hover {
    opacity: 0.5;
  }
}

@media screen and (min-width: $bp-medium) {
  .toggle {
    display: none;
  }
}
