.menu {
  z-index: 0;
  font-size: 18px;
  line-height: 1;
  font-weight: 300;
  background-color: $brown-light;

  &__list {
    margin-bottom: 80px;
  }

  &__link {
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 100%;
    padding: 16px 0;
    padding-bottom: 9px;
    padding-left: 43px;
    color: $white;

    &::before {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-3px);
      width: 5px;
      height: 5px;
      content: "";
      background-image: url("../img/svg/point.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: all 500ms ease-out;
    }

    &:focus,
    &:hover {
      color: $brown-light;
      background-color: $white;

      &::before {
        background-image: url("../img/svg/point-brown.svg");
      }
    }

    &--submenu {
      padding-left: 63px;

      &::before {
        background-image: none;
      }

      &:focus,
      &:hover {
        &::before {
          background-image: none;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .menu {
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      padding-left: 30px;
      margin: 0 auto;
      max-width: 1110px;
      box-sizing: border-box;
    }

    &__item {
      padding: 0;
      height: 62px;

      &:first-child > .menu__link::before {
        width: 0;
        height: 0;
      }

      &:first-child > .menu__link {
        margin-left: 0;
      }
    }

    &__link {
      margin-left: 30px;
      padding-top: 22px;
      padding-left: 0;

      &:focus,
      &:hover {
        color: $white;
        background-color: $brown-light;
      }

      &::before {
        left: -17px;
      }

      &:focus::before,
      &:hover::before {
        background-image: url("../img/svg/point.svg");
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
        transition: all 500ms ease-out;
      }

      &:focus::after,
      &:hover::after {
        background-color: $white;
      }
    }
  }
}


@media screen and (min-width: $bp-large) {
  .menu {
    font-size: 20px;
    line-height: 1.3;

    &__list {
      padding: 0 30px;
    }

    &__link {
      &::before {
        transform: translateY(0);
      }
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .menu {
    &__list {
      max-width: 1110px;
      margin: 0 auto;
      padding-left: 0;
    }

    &__item {
      height: 66px;
    }

    &__link {
      margin-left: 48px;

      &::before {
        left: -24px;
        transform: translateY(-3px);
      }
    }
  }
}

.menu__submenu-list {
  @media screen and (min-width: $bp-medium) { 
    display: none;
  }
}