.interlinks {
  text-align: center;
  padding-bottom: 20px;
}

.interlinks__list {

}

.interlinks__item {
  display: inline-block;

  &::after {
    content: "|";
    padding: 0 5px;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.interlinks__link {
  color: $orange;

  &:hover {
    color: $orange-dark;
    text-decoration: underline;
  }
}
