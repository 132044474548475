.heading {

}

.heading--h2 {
    margin: 0;
    padding-bottom: 21px;
    position: relative;
    font-size: 36px;
    font-family: $h2-font-family;
    line-height: 1.03;
    text-align: center;
    text-transform: uppercase;
    color: $brown-light;
}