.advantages {
  position: relative;
  padding-top: 75px;
  padding-bottom: 24px;
  background-color: $beige;
  // background-image: url("../img/picture/bg-advantages.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
  //   background-image: url("../img/picture/bg-advantages@2x.jpg");
  // }
}
