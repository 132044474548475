.manufacture {
  padding-top: 76px;
  padding-bottom: 75px;
  background-color: #f0e6cd;
}

.manufacture__slider-wrapper {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: $bp-xlarge) {
    max-width: 825px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $bp-large) {
    max-width: 540px;
  }

  @media (max-width: $bp-small) {
    max-width: 255px;
  }
}

.manufacture__slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  width: 39px;
  height: 60px;
  font-size: 0;
  background-color: #f0e6ce;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  cursor: pointer;
  z-index: 1;

  &--left {
    left: 0;
    text-align: left;
    border-radius: 0 30px 30px 0;
  }

  &--right {
    right: 0;
    text-align: right;
    border-radius: 30px 0 0 30px;
  }
}

.manufacture__slider-icon {
  fill: $green;
  transition: all 0.3s ease-out;

  &:hover {
    fill: $orange;
  }
}

.manufacture__slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.manufacture__slider .slick-track {
  align-items: center;
}

.manufacture__video-wrapper {
  margin: 0 auto;
  width: 540px;
  height: 304px;

  & iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media (max-width: $bp-small) {
    width: 90%;
  }
}
