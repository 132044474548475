.container {
  margin: 0 auto;
  max-width: 1110px;

  &--promo {
    padding: 50px 0 20px;
  }

  &--feedback {
    padding: 0;
    padding-top: 130px;
    padding-bottom: 76px;
  }
}

@media screen and (min-width: $bp-small) {
  .container {
    &--feedback {
      padding: 0 30px;
      padding-top: 130px;
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .container {
    &--promo {
      display: flex;
      flex-wrap: wrap;
      padding: 30px;
    }

    &--feedback {
      padding-top: 0;
    }

    &--footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0
    };
  }
}

@media screen and (min-width: $bp-large) {
  .container {
    &--promo {
      padding: 40px 30px;
      padding-top: 50px;
    }

    &--feedback {
      box-sizing: border-box;
    }

    &--footer {
      box-sizing: border-box;
      padding: 0 30px;
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .container {
    &--promo {
      // padding-top: 237px;
      padding-top: 75px;
      padding-bottom: 78px;
    }

    &--feedback {
      padding-left: 0;
      padding-right: 0;
    }

    &--footer {
      padding: 0;
    }
  }
}
