.section-item {
  margin-right: 30px;
  margin-bottom: 40px;
  width: 255px;

  &:last-child,
  &:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: $bp-xlarge) {
    &:nth-child(4n) {
      margin-right: 30px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-large) {
    margin-right: 60px;
    margin-left: 60px;

    &:nth-child(3n) {
      margin-right: 60px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-medium) {
    margin-right: 15px;
    margin-left: 15px;

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-small) {
    margin-right: auto;
    margin-left: auto;

    &:nth-child(3n) {
      margin-right: auto;
    }

    &:nth-child(2n) {
      margin-right: auto;
    }
  }

  &--examples {
    margin-bottom: 31px;
  }

  &--materials {
    margin-bottom: 31px;
  }
}

.section-item__icon-wrapper {
  margin: 0 auto 25px;
  position: relative;
  width: 93px;
  height: 93px;
  background-color: $white;
  border-radius: 50%;

  &--order {
    background-color: $beige;
  }
}

.section-item__icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-item__title {
  @include h4Styles(1.09);
  margin-bottom: 8px;
  text-align: center;

  &--features {
    color: $grey-dark;
  }

  &--order {
    color: $white;
  }

  &--advantages {
    color: $grey-dark;
  }

  &--examples {
    text-align: left;
    color: $grey-dark;
    max-width: 205px;
  }

  &--materials {
    text-align: left;
    color: $grey-dark;
    max-width: 205px;
  }
}

.section-item__descr {
  margin: 0 auto;
  max-width: 213px;
  color: $brown-dark;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.13;
  text-align: center;

  @media (max-width: $bp-small) {
    max-width: 100%;
  }

  & > br {
    @media (max-width: $bp-small) {
      display: none;
    }
  }

  &--order {
    color: $beige;
  }

  &--examples {
    margin: 0;
    max-width: 205px;
    text-align: left;

    & > span {
      display: block;
    }
  }

  &--materials {
    margin: 0;
    max-width: 205px;
    text-align: left;

    & > span {
      display: block;
    }
  }

  &--width {
    max-width: 220px;
  }
}

.section-item__link {
  display: block;
  text-decoration: none;
  transition: all 0.3s ease-out;
  height: 100%;
  background-color: $beige;

  &:hover {
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.35);
  }
}

.section-item__img {
  display: block;
  width: 255px;
  height: 191px;
  background-color: $beige;
}

.section-item__descr-wrapper {
  position: relative;
  padding: 23px 29px;
  background-color: $beige;
  flex-direction: column;
  flex-grow: 1;

  &::before {
    content: "";
    position: absolute;
    top: 27px;
    left: -4px;
    width: 17px;
    height: 14px;
    background-color: $green;
  }

  &--materials {
    padding-left: 30px;
    padding-right: 3px;
    // min-height: 171px;
    box-sizing: border-box;
  }
}


.section-item__list-item {
  position: relative;
  margin-left: 1px;
  padding-left: 9px;
  color: $brown-dark;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.13;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: $brown-dark;
    border-radius: 50%;
  }
}
