.section{
  overflow: hidden;
}

.section--content-block {
  padding-top: 75px;
  padding-left: 15px;
  padding-right: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section__subtitle {
  @include h4Styles(49.58px);
  margin-bottom: 21px;
  text-align: center;
}

.section__title {
  @include h2Styles();
  margin-bottom: 61px;
  padding-left: 15px;
  padding-right: 15px;

  &::after {
    @include h2Underline();
  }

  @media (max-width: $bp-small) {
    padding-left: 5px;
    padding-right: 5px;
  }

  &--with-subtitle {
    margin-bottom: 3px;
  }

  // By block
  &--features {
    color: $brown-light;
  }

  &--order {
    color: $white;
  }

  &--advantages {
    color: $grey-dark;

    @media (max-width: $bp-small) {
      font-size: 36px;
    }
  }

  &--examples {
    color: $brown-light;
    margin-bottom: 57px;

    @media (max-width: $bp-large) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: $bp-small) {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 36px;
    }
  }

  &--materials {
    color: $white;
    margin-bottom: 32px;

    @media (max-width: $bp-small) {
      font-size: 36px;
    }
  }

  &--catalog, 
  &--cta {
    color: $white;
    margin-bottom: 3px;
  }

  &--reviews {
    color: $grey-dark;
    margin-bottom: 64px;
  }

  &--about {
    color: $green;
    margin-bottom: 78px;
  }

  &--manufacture {
    color: $brown-light;
    margin-bottom: 48px;

    @media (max-width: $bp-small) {
      font-size: 36px;
    }
  }

  &--partners {
    color: $grey-dark;
  }

  &--advices {
    color: $white;
    margin-bottom: 43px;

    @media (max-width: $bp-small) {
      font-size: 38px;
    }
  }
}

.section__list {
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: $bp-xlarge) {
    max-width: 855px;
  }

  @media (max-width: $bp-large) {
    max-width: 750px;
  }

  @media (max-width: $bp-medium) {
    max-width: 570px;
  }

  @media (max-width: $bp-small) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    justify-content: center;
  }

  &--examples {
    margin-bottom: 9px;
  }

  &--materials {
    margin-bottom: 9px;
  }
}

.section__link {
  display: block;
  margin-left: auto;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-right: 21px;
  width: 276px;
  color: $white;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.18;
  background-color: $orange;
  text-decoration: none;
  text-transform: uppercase;
  text-align: right;
  border-radius: 22px 0 0 22px;
  box-sizing: border-box;
  letter-spacing: -0.2px;
  transition: all 0.5s ease-out;

  @media (max-width: $bp-xlarge) {
    margin-right: auto;
    border-radius: 22px;
  }

  &:hover {
    background-color: #f35601;
  }
}
