.reviews-slide {
  flex-shrink: 0;
  max-width: 795px;
  margin-left: 80px;
  margin-right: 80px;
  text-align: center;
  box-sizing: border-box;
  outline: none;

  @media (max-width: $bp-large) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: $bp-medium) {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.reviews-slide__text {
  margin: 0;
  margin-bottom: 14px;
  max-width: 795px;
  color: $grey-dark;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.54;

  @media (max-width: $bp-large) {
    max-width: 665px;
  }

  @media (max-width: $bp-medium) {
    max-width: 465px;
  }

  @media (max-width: $bp-small) {
    max-width: 230px;
  }
}

.reviews-slide__source {
  margin: 0;
  color: $brown-dark;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.54;
}
