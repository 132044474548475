.cta {
  padding-top: 77px;
  padding-bottom: 75px;
  position: relative;
  background-color: $brown-light;
  z-index: 0;

  @media (max-width: $bp-small) {
    padding-top: 130px;
  }


  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 676px;
    height: 406px;
    background-image: linear-gradient(to top left, transparent 0, transparent 50%, $brown-light 50%, $brown-light 100%);
    z-index: -1;

    @media (max-width: $bp-xlarge) {
      width: 400px;
      height: 250px;
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 1084px;
    height: 565px;
    background-image: url("../img/picture/bg-catalog.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2;

    // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    //   background-image: url("../img/picture/bg-catalog@2x.jpg");
    //   background-size: 1084px auto;
    // }

    @media (max-width: $bp-xlarge) {
      width: 950px;
      top: 55%;
      left: 47%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: $bp-large) {
      width: 750px;
    }

    @media (max-width: $bp-medium) {
      width: 550px;
    }

    @media (max-width: $bp-small) {
      display: none;
    }
  }
}

.cta__btn-block {
  text-align: center;
}


.cta__subtitle {
  @include h4Styles(49.58px);
  color: $white;
  margin-bottom: 21px;
  text-align: center;
}

.catalog__list {
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: $bp-xlarge) {
    max-width: 855px;
  }

  @media (max-width: $bp-large) {
    max-width: 750px;
  }

  @media (max-width: $bp-medium) {
    max-width: 570px;
  }

  @media (max-width: $bp-small) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    justify-content: center;
  }
}

.catalog__link {
  display: block;
  margin-left: auto;
  width: 282px;
  color: $white;
  font-size: 17px;
  font-weight: 700;
  line-height: 2.52;
  background-color: $orange;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 22px 0 0 22px;
  transition: all 0.5s ease-out;

  @media (max-width: $bp-xlarge) {
    margin-right: auto;
    border-radius: 22px;
  }

  &:hover {
    background-color: #f35601;
  }
}
