.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000000;

  &--call {
    z-index: 11;
  }

  &--form {
    z-index: 13;
  }

  &--open {
    display: block;
    opacity: 0.8;
  }
}
