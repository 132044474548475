.upload {
  &__wrapper {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    height: 44px; //field height
    margin-right: 0;
    border: 1px solid $grey-field-border;
    border-radius: 0 30px 30px 0; // half height

    &::after {
      content: attr(data-note);
      position: absolute;
      top: -1px; // account for border
      right: -1px;
      width: 108px; // btn width
      height: calc(100% + 2px); // account for border
      box-sizing: border-box;
      padding-top: 13px;
      padding-bottom: 6px;
      padding-left: 10px;
      padding-right: 20px;
      font-size: 17px;
      line-height: 1;
      text-transform: uppercase;
      color: $white;
      background-color: $grey-btn;
      border: 1px solid $grey-btn;
      border-radius: 0 30px 30px 0; // half height
      transition: all 500ms ease-out;
    }

    &:hover::after {
      background-color: $grey-btn-hover;
    }
  }

  &__path {
    display: block;
    box-sizing: border-box;
    padding: calc((44px - 17px) / 2);
    padding-left: 18px;
    font-size: 17px;
    line-height: 1;
    font-weight: 300;
    color: $grey-btn;
  }

  &__input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

@media screen  and (min-width: $bp-xlarge) {
  .upload {
    &__wrapper {
      height: 44px;

      &::after {
        width: 108px;
        padding-top: 14px;
        font-size: 16px;
        line-height: 1.125;
      }
    }

    &__path {
      padding: calc((44px - 16px) / 2);
    }
  }
}
