.examples-item {
  margin-right: 30px;
  width: 255px;

  &:last-child,
  &:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: $bp-xlarge) {
    &:nth-child(4n) {
      margin-right: 30px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-large) {
    margin-right: 60px;
    margin-left: 60px;

    &:nth-child(3n) {
      margin-right: 60px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-medium) {
    margin-right: 15px;
    margin-left: 15px;

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
