// Стилизация заголовков секций (h2)
@mixin h2Styles () {
  margin: 0;
  padding-bottom: 21px;
  position: relative;
  font-size: 48px;
  font-family: $h2-font-family;
  line-height: 1.03;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $bp-medium) {
    font-size: 36px;
  }

}

// Стилизация заголовков преимуществ/карточек контента (h4)
@mixin h4Styles ($line-height) {
  margin: 0;
  font-size: 21px;
  font-weight: 700;
  line-height: $line-height;
  text-transform: uppercase;
}

// Underline заголовков секций
@mixin h2Underline () {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 265px;
  height: 7px;
  background-color: currentColor;
}
