.about {
  position: relative;
  padding-top: 76px;
  padding-bottom: 92px;
  background-color: $beige;
  overflow: hidden;
  z-index: 0;

  @media (max-width: $bp-small) {
    padding-top: 130px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 654px;
    height: 542px;
    background-image: url("../img/picture/bg-logo.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;

    // @media (min-resolution: 144dpi),
    //   (min-resolution: 1.5dppx) {
    //   background-image: url("../img/picture/bg-logo@2x.png");
    // }
  }
}

.about__slider-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: $bp-xlarge) {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.about__slider-btn {
  position: relative;
  padding: 0;
  width: 19px;
  height: 33px;
  font-size: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.about__slider-icon {
  fill: $green;
  transition: all 0.3s ease-out;

  &:hover {
    fill: $orange;
  }
}

.about__slider {
  margin: 0 auto;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}
