.partners-slide {
  flex-shrink: 0;
  padding-left: 25px;
  padding-right: 25px;
  outline: none;

  @media (max-width: $bp-xlarge) {
    margin-left: 25px;
    margin-right: 25px;
  }

  @media (max-width: $bp-large) {
    margin-left: 45px;
    margin-right: 45px;
  }

  @media (max-width: $bp-medium) {
    margin-left: 60px;
    margin-right: 60px;
    padding-left: 45px;
  }

  @media (max-width: $bp-small) {
    padding-left: 25px;
  }
}

.partners-slide__img {
  &--ckmp {
    transform: translateY(3px);
  }

  &--egger {
    transform: translateY(3px);
  }

  &--kronospan {
    transform: translateY(3px);
  }
}
