.columns {
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.columns__item {
    flex-grow: 0;
    flex-shrink: 0;

    &--of2 {
        flex-basis: 100%;
        max-width: 100%;

        @media (min-width: $bp-medium) {
            flex-basis: 48%;
            max-width: 48%;
        }
    }

    &--of3 {
        flex-basis: 100%;
        max-width: 100%;

        @media (min-width: $bp-medium) {
            flex-basis: 30%;
            max-width: 30%;
        }
    }
}