.gallery {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: $bp-xlarge) {
    max-width: 855px;
  }

  @media (max-width: $bp-large) {
    max-width: 750px;
  }

  @media (max-width: $bp-medium) {
    max-width: 570px;
  }

  @media (max-width: $bp-small) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
    justify-content: center;
  }
}

.gallery__item {
  margin-right: 30px;
  margin-bottom: 40px;
  width: 255px;

  &:last-child,
  &:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: $bp-xlarge) {
    &:nth-child(4n) {
      margin-right: 30px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-large) {
    margin-right: 60px;
    margin-left: 60px;

    &:nth-child(3n) {
      margin-right: 60px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-medium) {
    margin-right: 15px;
    margin-left: 15px;

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-small) {
    &:nth-child(2n) {
      margin-right: 15px;
    }
  }
}
