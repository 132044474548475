.modal-manufacture {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.35);
  z-index: 9999;
}

.modal-manufacture__img-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-manufacture__img {
  max-width: 1200px;
  // width: 255px;
}

.modal-manufacture__btn {
  position: absolute;
  top: 30px;
  right: 10px;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 34px;
  font-size: 0;
  background-color: transparent;
  background-image: url("../img/svg/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
  cursor: pointer;
}
