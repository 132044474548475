.promo {
  // padding-top: 158px;
  line-height: 1;
  color: $white;
  background-color: $bg-fallback;
  // background-image: url("../img/picture/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right -390px;

  // @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
  //   background-image: url("../img/picture/banner@2x.jpg");
  // }

  &__intro {
    padding: 0 25px;
    padding-bottom: 40px;
    text-transform: uppercase;
  }

  &__title {
    margin: 0;
    padding-bottom: 18px;
    font-family: "GoodCyr", "Arial", sans-serif;
    font-size: 41px;
  }

  &__note {
    padding-bottom: 26px;
    padding-right: 30px;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
  }

  &__list {
    padding-bottom: 54px;
  }

  &__item {
    position: relative;
    padding: 6px 0;
    padding-left: 30px;
    font-size: 14px;

    &::before {
      position: absolute;
      left: 0;
      top: calc(50% - 2px);
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      content: "";
      background-image: url("../img/svg/check.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

@media screen and (min-width: $bp-medium) {
  .promo {
    // padding-top: 244px;
    background-position: center;

    &__intro {
      display: block;
      box-sizing: border-box;
      flex-basis: 50%;
      padding-bottom: 0;
    }

    &__note {
      padding-bottom: 40px;
    }

    &__list {
      padding-bottom: 50px;
    }

    &__form {
      display: block;
      box-sizing: border-box;
      flex-basis: 50%;
    }
  }
}

@media screen and (min-width: $bp-large) {
  .promo {
    padding-top: 0;

    &__intro {
      flex-basis: 38%;
      padding: 0;
    }

    &__title {
      padding-right: 80px;
      font-size: 48px;
      line-height: 1.04;
    }

    &__note {
      font-size: 20px;
      line-height: 1.25;
    }

    &__list {
      font-size: 16px;
      line-height: 1.56;
    }

    &__item {
      padding-left: 30px;

      &::before {
        width: 20px;
        height: 20px;
      }
    }

    &__form {
      flex-basis: 38%;
    }
  }
}

@media screen and (min-width: $bp-xlarge) {
  .promo {
    &__intro {
      flex-basis: unset;
      width: 380px;
      margin-top: -8px;
    }

    &__title {
      padding-top: 0;
    }

    &__note {
      padding-right: 100px;
      padding-bottom: 23px;
    }

    &__item {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 17px;
      line-height: 1.11;
    }

    &__form {
      flex-basis: unset;
    }
  }
}
