.manufacture-slide {
  flex-shrink: 0;
  margin-left: 30px;
  transition: all 0.3s ease-out;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.4);
  }
}

.manufacture-slide__img {
  width: 255px;
  height: 191px;
  cursor: pointer;
}
