.message {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 24px - 2px);
  height: calc(100% - 17px - 2px);
  box-sizing: border-box;
  padding: 4px;
  padding-top: 11px;
  padding-bottom: 7px;
  padding-left: 19px;
  font-size: 17px; // same as input text
  line-height: 1;
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    right: -18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &--error {
    color: $red;
    background-color: $white;
    opacity: 0;

    &::after {
      background-image: url("../img/svg/form_no.svg");
    }
  }

  &--success {
    opacity: 0;

    &::after {
      background-image: url("../img/svg/form_yes.svg");
    }
  }
}

.checkbox-error {
  color: $red;
  background-color: $white;
  opacity: 0;

  &::after {
    background-image: url("../img/svg/form_no.svg");
  }
}