.catalog-item {
  margin-right: 30px;
  margin-bottom: 40px;
  width: 255px;

  &:last-child,
  &:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: $bp-xlarge) {
    &:nth-child(4n) {
      margin-right: 30px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-large) {
    margin-right: 60px;
    margin-left: 60px;

    &:nth-child(3n) {
      margin-right: 60px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-medium) {
    margin-right: 15px;
    margin-left: 15px;

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: $bp-small) {
    &:nth-child(2n) {
      margin-right: 15px;
    }
  }
}

.catalog-item__link {
  text-decoration: none;
  transition: all 0.5s ease-out;

  &:hover .catalog-item__title {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);
  }

  &:hover .catalog-item__goods-wrapper {
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.4);
  }
}

.catalog-item__title {
  @include h4Styles(49.58px);
  color: $white;
  max-width: 255px;
  transition: all 0.3s ease-out;
  margin-bottom: 0;
  line-height: 1.3;
  margin-top: 10px;
  text-align: center;
  max-height: 81px;
}

.catalog-item__goods-wrapper {
  position: relative;
  width: 255px;
  height: 191px;
  background-color: $brown-light;
  transition: all 0.3s ease-out;
}

.catalog-item__price {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 191px;
  color: $green;
  font-size: 17px;
  font-weight: 700;
  line-height: 2.52;
  background-color: $white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0 22px 22px 0;
}
