//Брейкпойнты
$bp-small: 576px;
$bp-medium: 768px;
$bp-large: 992px;
$bp-xlarge: 1200px;

//Мин ширина экрана
$width-min: 320px;

//Цвета
$white: #ffffff;
$beige: #f4edd5;
$orange: #ff6600;
$orange-dark: #f35601;
$green: #3d8a3f;
$green-dark: #367f39;
$brown-light: #8f6650;
$brown-dark: #7d5a47;
$grey-light: #666666;
$grey-dark: #333333;

$grey-btn: #999999;
$grey-btn-hover: #8e8e8e;

$green-tel-hover: #aaccab;

$green-logo-hover: #3a833c;
$brown-logo-hover: #88614c;

$grey-field-border: #cccccc;

$green-employee: #39853c;

$bg-fallback: #3f533a;

$red: #d72525;

// Семейство шрифтов для h2
$h2-font-family: "GoodCyr", "Arial", sans-serif;

// Значения для фоновых изображений под ретину
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
