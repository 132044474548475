.about-slide {
  flex-shrink: 0;
  max-width: 795px;
  margin-left: 80px;
  margin-right: 80px;
  text-align: center;
  box-sizing: border-box;
  outline: none;

  @media (max-width: $bp-large) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: $bp-medium) {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.about-slide__text-caption {
  margin: 0;
  margin-bottom: 3px;
  color: $grey-dark;
  font-size: 21px;
  font-family: $h2-font-family;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
}

.about-slide__text {
  margin: 0;
  max-width: 730px;
  color: $grey-dark;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.59;

  @media (max-width: $bp-large) {
    max-width: 665px;
  }

  @media (max-width: $bp-medium) {
    max-width: 465px;

    & > br {
      display: none;
    }
  }

  @media (max-width: $bp-small) {
    max-width: 235px;
  }
}
