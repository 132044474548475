.advices-item {
  margin-bottom: 33px;
  display: flex;
  width: 540px;

  @media (max-width: $bp-small) {
    max-width: 320px;
    flex-direction: column;
    align-items: center;
  }

  &:first-child .advices-item__descr-wrapper {
    margin-bottom: 17px;
    max-width: 300px;

    @media (max-width: $bp-small) {
      max-width: 500px;
    }
  }

  &:nth-child(2) .advices-item__descr-wrapper {
    margin-bottom: 17px;
    max-width: 410px;

    @media (max-width: $bp-small) {
      max-width: 500px;
    }
  }
}

.advices-item__icon-wrapper {
  width: 65px;
  height: 65px;

  @media (max-width: $bp-small) {
    margin-bottom: 17px;
  }
}

.advices-item__icon {
  max-width: none;
}

.advices-item__descr-wrapper {
  margin-left: 29px;
  padding-top: 10px;
  letter-spacing: -0.08px;
  box-sizing: border-box;

  @media (max-width: $bp-small) {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 500px;
  }
}

.advices-item__title {
  @include h4Styles(22.92px);
  color: $white;
  margin-bottom: 8px;
}

.advices-item__descr {
  margin: 0;
  color: $white;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.13;
}
