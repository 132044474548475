.logo {
  transition: all 500ms ease-out;

  &__green {
    fill: $green;
  }

  &__brown {
    fill: $brown-light;
  }

  &:hover {
    .logo {
      &__green {
        fill: $green-logo-hover;
      }

      &__brown {
        fill: $brown-logo-hover;
      }
    }
  }

  &__white {
    fill: $white;
  }
}
