.partners {
  padding-top: 76px;
  padding-bottom: 76px;
}

.partners__slider-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}


.partners__slider-btn {
  position: relative;
  padding: 0;
  width: 49px;
  height: 33px;
  font-size: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  cursor: pointer;

  @media (max-width: $bp-xlarge) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.partners__slider-icon {
  fill: $green;
  transition: all 0.3s ease-out;

  &:hover {
    fill: $orange;
  }
}

.partners__slider {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.partners__slider .slick-track {
  align-items: center;
}
